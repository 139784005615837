@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&family=Rakkas&display=swap');

body {
  font-family: 'Cairo', sans-serif;
}
html, body {
    height: 100%;
  }
/* Default RTL layout */
.rtl-layout {
    direction: rtl;
    text-align: right;
   
    
  }
  
  /* LTR layout */
  .ltr-layout {
    direction: ltr;
    text-align: left;
  }
  
  /* Additional custom styles if needed */
  .rtl-layout .react-tel-input .country-list{
  direction: rtl !important;
  }
  .react-tel-input .form-control{
    width: 100% !important;
    
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9;
    display: none;
  }
  
  .overlay.active {
    display: block;
  }
  
  .content.blur {
    filter: blur(5px);
  }
  .shadow-custom {
    box-shadow: 0px 4px 4px 0px #00000040;
  }



/* Optionally, hide any other elements like labels or dropdowns */
.wpwl-label,
.wpwl-wrapper-brand {
  display: none !important;
}

  

  